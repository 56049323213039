import { Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import React from "react";
import CardContainer from "./Cards/CardContainer";
import GalleryMain from "./Gallery/GalleryMain";
import logo from "./Images/Cruisenewbie.png";

const Main = () => {
  return (
    <div id="main">
      <Container fixed disableGutters maxWidth="lg">
        <div className="main-intro-logo">
          <img src={logo} />
          <Typography variant="h5" color="text.secondary">
            Welcome to Cruise Newbie!
          </Typography>
          <Typography variant="h5" color="text.secondary">
            Your best source of advice for first time Cruisers and Cruise
            addicts!
          </Typography>
        </div>
        <div className="main-logo">
          <GalleryMain />
          {/*<img src={logo} id="logo" /> */}
        </div>

        <div className="main-intro">
          <Link to="/articles">
            <Typography variant="h3" className="main-intro-head" height={100}>
              Articles
            </Typography>
          </Link>
        </div>
        <div className="main-cards">
          <CardContainer />
        </div>
        {/*<div className="main-intro">
          <Typography
            variant="h3"
            className="main-intro-head"
            height={100}
            fontSize={30}
          >
            About Us
          </Typography>
        </div> */}
      </Container>
    </div>
  );
};

export default Main;
