export const articles = [
  {
    id: 1,
    title: "Three Reasons why Cruises are perfect for Honeymoons",
    article:
      "You picked the best dress, you picked the most delicious food, you even picked the best color napkins for the wedding party, but did you pick the best honeymoon? Honeymoons are important milestones in the life of every couple. It is important to pick a trip that you and your partner will never forget. That’s why we at cruise newbie recommend a Honeymoon Cruise as the best way to have fun on your honeymoon and make memories that will last a lifetime. Below are 4 reasons why you should consider a cruise for your honeymoon.",
    image: "https://testestestestext.s3.us-west-2.amazonaws.com/IMG_9884.jpg",
    sub_title_1: "Romance",
    sub_article_1:
      "When you are in a cruise you can watch the sun set from the balcony in your room together. You can have a private dinner for two, go dancing all night, or just relax under the sun together while drinking champagne. You can spend the night  looking at the stars together from the privacy of your own balcony. Cruises have an incredible number of options to spend time together and enjoy being with your new partner. While some people believe cruises are crowded, the ships are very big and every cruise line works very hard to create special places where you and your partner can spend time together.",
    sub_title_2: "Luxury and Service",
    sub_article_2:
      "Honeymoons are very special occasions, and you should receive the best service in a luxurious environment.  Cruises have some of the best service in the travel industry. When you are on a cruise you will be assigned a team of wait staff in the main dining room for your entire trip. This team will do their best to learn what you like and don’t like, and work very hard to make sure that every meal is memorable. You will also be assigned a cabin attendant whose job is to make sure your room is always to the highest standard. They clean your room twice a day, and are readily available just in case you need something like extra towels, or more shampoo. Are you relaxing in the jacuzzi? Waiters are available to bring you whatever drink you like from the bar. Would you like to eat something special? Room service in most cruise lines is included free of charge. If you want to be pampered, cruises are some of the best places for that in the world.",
    sub_title_3: "Adventure",
    sub_article_3:
      "It is also important to make fun and exciting memories with your new partner when you are on a honeymoon, and cruise ships are perfect for that. On many cruise ships you can learn how to surf together, learn how to rock climb, or even how to skydive! When your ship reaches a port you can explore coral reefs, ride jet skis, or even fly on some the longest zip lines in the world. If you enjoy history, you can explore historic cities fille with amazing cultures and even ancient Mayan Ruins! The possibilities to make unforgettable memories are endless! Value The best part is that all this is available for a fraction of the cost of regular vacations. While prices for cruises do change depending on the season, ship, and itinerary, you will find that cruises offer incredible value compared to other honeymoons. For the cost of a hotel in other types of vacations, you can get a balcony room, breakfast, lunch and dinner, and many exciting activities. Even the more luxurious and romantic specialty restaurants are a fraction of the price of similar restaurants on land.  If you are lucky and can afford to spend more money, you will find that the suites on cruises are some of the most amazing hotel accommodations in the world.",
    sub_article_4: "Ready to book the honeymoon cruise of your dreams?",
    link: "three-reasons-why-cruises-are-perfect-for-honeymoons",
    next_link: 2,
  },
  {
    id: 2,
    title: "3 Things to consider when booking a flight for your cruise",
    article:
      "So, you checked your travel dates, you picked the ship that is best sized for your group, you picked the best itinerary that covers your style, and you bought the drink package. You got everything you need, right? Don’t forget to book a flight! This article has three important things to consider when you book a flight.",
    image: "https://testestestestext.s3.us-west-2.amazonaws.com/IMG_0811.jpg",
    sub_title_1: "Where are you located, and where does your cruise sail from?",
    sub_article_1:
      "t may not seem like a big deal at first but where you live and where your cruise sets sail from can really affect your vacation. If planned poorly it can even mean missing your ship! The article here covers your different options, but for now let’s just say that distance and time zone matter. If you live near a cruise terminal, you can drive to the port and conveniently park your car in one of the parking garages specifically built for cruisers. If you don’t live within driving distance but are in the same time zone you may be able to catch a same day flight. If you live in a different time zone, then you may have to dedicate a specific travel day just to get there. Be sure to check out this article to fully understand your options.",
    sub_title_2: "What type of luggage will you use?",
    sub_article_2:
      "Here at cruise newbie one of our top ten must do rules is “always pack a carry on!” We know from personal experience that not having your luggage on a cruise is not fun at all! If your luggage does not make it to the ship on time, you could potentially go the entire cruise without luggage. This means that careful consideration must be given to what items will be in your carry on, and what you are willing to not have with you on the trip.  Things like how many bags you will check in, how likely are your bags to be transferred during a layover, and even how long does it take for an airport to unload your bags at your destination are very important things to keep in mind.",
    sub_title_3: "How will you get from the airport to the cruise?",
    sub_article_3:
      "Will you take a charter bus, taxi, uber, rental car, or public transport? All of these are potential ways to get to the airport but as before what you choose could be the difference between a seamless transition into fun and relaxation, or all the stress and disappointment of missing your sail time. Once again proper planning saves the day.",
    sub_article_4:
      "This article only begins to scratch the surface! Don’t worry, its not as overwhelming as it sounds. With some sensible planning and the advice in our guides you should be able to do all your planning months in advance, and just show up ready to begin your vacation and relax (or start your adventure whatever you prefer).",
    link: "3-things-to-consider-when-booking-a-flight-for-your-cruise",
    next_link: 3,
  },
  {
    id: 3,
    title: "Should I get the drink package? Part 1: The Math",
    article:
      "This is a question that we often get as people wonder if it’s worthwhile to add 100’s of dollars extra to their trip to get the drink package. While there are many reasons to get a drink package it all depends on the individual cruiser and what they want to get out of their vacation. The following are some considerations to keep in mind as you make the decision that is best for you. This discussion is broken down into two articles. The first one covers the “math” around the drink packages, while part two here covers the “intangibles” or things that are hard to quantify.",
    image: "https://testestestestext.s3.us-west-2.amazonaws.com/Drinks.jpg",
    sub_title_1: "The Math",
    sub_article_1: `All discussions around the drink package always start with the most basic question. Am I getting a good deal? Here at CruiseNewbie we believe that most drink packages are generally a good deal. The cruise lines have great purchasing power and often pass the savings on to the consumer in the form of drink packages. For some cruisers the drink package is a key part of the cruise experience, so cruise lines go to great lengths to make the packages attractive. As always whether the drink package is a good deal or not comes down to the individual passenger. Some passengers are perfectly fine, only having 1-2 drinks a day. For these passengers the package likely does not make sense. Other passengers will likely get their money’s worth on the drink packages no matter what and this article is not for them. This article is best suited for someone that does plan to drink but is not sure how much they really would on a cruise. The vignette below shows a typical day at sea, and all the times you can take advantage of the drink package. (Prices are estimated based on averages and may vary depending on ship, itinerary, and cruise line. The prices on your sailing may be different! Also please drink responsibly.) A day at Sea`,
    sub_title_2: "",
    sub_article_2:
      "8:00 AM: You wake up energized and head to the gym. Afterwards you stop by the smoothie bar and order a delicious banana strawberry smoothie with extra protein powder for your recovery. $12.00",
    sub_title_3: "",
    sub_article_3:
      "10:00 AM: You are outside having a great game of mini-golf, but the heat outside is already at full blast. You decide to get a bottle of water. $4.00",
    sub_article_4:
      "12:00 PM: You hit the buffet for lunch and decide a light beer would go great with your freshly grilled burger. $8.00",
    sub_article_5:
      "1:00 PM: You finally hit the pool to relax, better stay hydrated with another bottle of water. $4.00.",
    sub_article_6:
      "4:00 PM: What better way to wrap up your day of lounging by the pool than jumping into the hot tub. While there you noticed that everyone is keeping cool by sipping blended mojitos. Why not get one? $12.00",
    sub_article_7:
      "7:00 PM: Dinner time at last! The steak looks amazing. Why not pair it with a glass of wine? $12.00",
    sub_article_8:
      "9:00 PM: You caught a fantastic show after dinner, and now just want to wind down before going to bed, how about listening to some piano favorites while sipping on a smooth drink before going to bed? $12.00.",
    sub_article_9:
      "9:20 PM: Let’s make sure you are properly hydrated for your adventure tomorrow. You order a bottle of water at the bar before you head to your room. $4.00 Total for 1 day at sea: $80.00 (This didn’t even include gratuities so we could be talking between $90-$100!)",
    sub_article_10:
      "As you can see a typical day can quickly add up! So how much is a drink package and is it worth it? We always buy our drink package before getting on the ship to get the best prices and have recently paid between $60 and $75. (Here again prices depend on your cruise line and itinerary.) Additional savings can be attained if you combine your drink package with high-speed Wi-Fi.",
    sub_article_11:
      "Also, it’s important to note that the drink package on many cruise lines is not limited to alcohol. (Check the details before purchasing). In this example 30% of the drinks bought had no alcohol in them whatsoever. Another bonus is that drink packages are usually honored onshore at special destinations like Coco Cay and Labadee. (Check the details of your package and itinerary to make sure.)",
    sub_article_12:
      "Bottomline: So, does the drink package make sense for YOU? Only you can make that decision. What we have found in our experience on multiple trips is that the package more than pays for itself between alcoholic drinks, non-alcoholic drinks, and gratuities. For us we believe we break even on the bottled water alone (we can easily drink 3-4 bottles of water on a busy night of dancing), the alcoholic drinks are just extra. Also do keep in mind that people’s habits change on vacation. (It’s not normal to drink bloody mary’s for breakfast on a regular day, but it’s a common and fun breakfast drink on vacation). Finally a decision like the drink package is more than just math. There are a lot of intangible benefits that drink packages can add to your vacation. These benefits are explored in Should I get the drink package? Part 2: The Intangibles",
    link: "should-i-get-the-drink-package-part1-the-math",
    next_link: 4,
  },
  {
    id: 4,
    title: "We took a red-eye flight to our cruise!!",
    article:
      "We like to take as many cruises as possible to as many different destinations on as many different ships as possible. This means that we are often stuck having to fly to the other side of the country to make it to our departure port. For situations like this many experienced cruisers will tell you to arrive one day earlier at your port of departure and spend the night at a hotel. This is great advice as the added time reduces the chance of bad luck derailing your trip, but it comes with added expenses and other drawbacks. To remedy this, we have started taking red-eye flights on some of our cruises!",
    image: "https://testestestestext.s3.us-west-2.amazonaws.com/IMG_1913.jpg",
    sub_title_1: "What is a Red-eye flight?",
    sub_article_1:
      "A red-eye flight usually flies at night. Meaning you board very late in the day and arrive at your destination very early the next morning. They can be very tiring, but create some very beneficial time efficiencies.",
    sub_title_2: "Why Red-eye flights?",
    sub_article_2:
      "Let’s face it red eye flights can be one of the worst ways to travel. They leave late, they tend to be very long, and you either sleep in a very uncomfortable seat, or you don’t sleep at all. Not a great way to start your vacation! That being said there are some very good reasons to take a red-eye:",
    sub_title_3: "They can be cheaper",
    sub_article_3:
      "Compared to mid-day flights late night and early morning flights are some of the cheapest available. This has to do with supply and demand as very few are willing to sacrifice their sleep to save money. At the same time if you are on a budget a red-eye flight is a great way to save money which you can apply to the drink package or some other fun stuff on your trip. A red-eye will also give you a lot of the same benefits you get by flying the day before without having to spend extra on transportation, meals, and lodging to cover your extra vacation days.",
    sub_title_4: "They are easier on your schedule",
    sub_article_4:
      "We are all busy and leaving one day early for many means having to use precious vacation days, missing school, or not being able to attend other important events. Since red-eyes tend to be outside of most people’s work/school hours, you can save yourself from having to miss work and school while still getting a lot of the benefits of flying in one day early. I’ve been able to wrap up work at 5 which gives me 5-6 hours to board a flight at 11:30 PM.",
    sub_title_5: "They reduce stress",
    sub_article_5:
      "Yes, you read that right. Depending on your itinerary most red-eye flights will have you on your destination between 6 and 10 AM. This is super important on embarkation day. The earlier you arrive, the earlier you can board the ship and start your vacation! We have taken red eye flights that landed at 8 AM. Which gave us time to stretch our legs, collect our luggage, take a ride-share to the port and board the ship at 11:30 AM. By noon we were taking advantage of the drink package, drinking cocktails and having lunch at the buffet! Had we left the same day, around the time we were already on the ship we’d likely still be running to catch a connecting flight, and praying we arrive before boarding ends at 4 PM.",
    sub_title_6: "They decrease risk",
    sub_article_6:
      "Similar to leaving the day before, a red-eye increases the amount of time between you leaving your house and making it to the ship. Things like weather delays, or delayed baggage become much more manageable as you just have more time to serve as a cushion just in case. We like to talk to our ride-share drivers, and some have told us of having given rides to cruisers who missed their ship because of heavy traffic. You do not want to take any chances on embarkation day, and red-eyes greatly help with that by ensuring you arrive early enough to deal with any unplanned problems.",
    sub_title_7: "You probably weren’t going to sleep that well anyway",
    sub_article_7:
      "If you’re like us, you probably get so excited about your vacation that you don’t sleep that much the night before anyway. We’ve been in situations where we stayed up till 10 to 11 PM packing and doing last minute things. Then could not go to sleep due to all the excitement, then had to wake up around 3 to 4 AM to catch a 7:30 AM flight. By the time the plane takes off we are so exhausted we end up sleeping during most of the flight anyway. Every time this has happened to us, we always think we should have taken the red-eye!",
    sub_title_8: "Conclusion",
    sub_article_8:
      "To be fair red-eyes do have their drawbacks and are not for everybody. If paying for an extra day of hotel and travel expenses is no issue, and you can take off an extra day from work or school then leaving a day early will give you all the benefits of a red-eye without the downsides. That said, if you are like us who have very limited vacation days, and are trying to stretch every vacation dollar, then we highly recommend you consider taking a red-eye flight to your next cruise.",
    link: "what-is-a-red-eye-flight",
    next_link: 1,
  },
  {
    id: 5,
    title: "10 must haves for your carry on!",
    article:
      "Before we begin, we’d like to point out one of the most important tips we give here at cruise newbie: ALWAYS travel with a carry on whenever you cruise! There are sooo many different ways in which your luggage can get delayed, misplaced, or worse destroyed. While millions of people travel every year without losing their luggage, being on a ship moving from island to island likely means that if for ANY reason your luggage doesn’t make it to the ship’s departure, you won’t have it for the whole trip. We know from personal experience that you don’t want to spend 7 days on a ship without your luggage.  On top of that we highly recommend that every single cruiser in your party take advantage of their carry-on allowance. Even kids can take a backpack or a small rollie suitcase with the essentials they will need. Make sure your carry on has the following items:",
    image: "https://testestestestext.s3.us-west-2.amazonaws.com/IMG_2332.jpg",
    sub_title_1: "Medications",
    sub_article_1:
      "It’s common travel knowledge to always take your medications on your carry on. This advice becomes even more important if you are going on a cruise. You don’t want to be stuck on the sea for multiple days without access to your medications. Bonus tip: Make sure to carry over-the-counter medications such cold medicice, anti-acids, laxatives, and bismuth (you know the pink stuff). It’s easy to get carried away and having these handy may turn a rough evening into a great night.",
    sub_title_2: "ID Cards/Passports",
    sub_article_2:
      "Without these you can’t get on the ship period. Don’t risk it and make sure they are in either on your person or a carry-on bag.",
    sub_title_3: "Hygiene kit",
    sub_article_3:
      "Like medicines you want to make sure you have your toothpaste, toothbrush, face scrubs, etc with you. These can be purchased on board or in a port of call, but will often be more expensive or they may not have the brand you are used to.",
    sub_title_4: "Cosmetics",
    sub_article_4:
      "Sure you can buy new cosmetics on the ship, but will they have your correct shade or brand? Also, the cosmetics on the ship are on the high end, so be ready to pay more.",
    sub_title_5: "Clothes for a few days",
    sub_article_5:
      "Due to space you likely won’t be able to pack your entire wardrobe onto your carry on, but having clothes for 2-3 days can definitely save your vacation. A few days of clothes can tie you over and you can get them washed while on the ship. Note: Different ships offer different laundry amenities with some offering full laundromats while others will charge per item. On more restrictive sailings we will sometimes go as far as making sure we have enough clean underwear for the entire trip.",
    sub_title_6: "Your Bathing Suit",
    sub_article_6:
      "This tip is definitely a pro-tip! Yes, you want to make sure your bathing suit makes it onto the ship no matter what, but there’s an added benefit new cruisers are not aware of. Depending on when you board the ship, there’s a high chance your luggage will not have made it to your room, or maybe you wont even have access to your room at all until later in the day. If you have your bathing suit on you, you can change in one of the changing rooms on the pool deck, and hit the pool before everyone else!",
    sub_title_7: "Chargers",
    sub_article_7:
      "Make sure you always have power to take pictures of your vacation! Always carry your charger in your carry-on. Chargers are available on many ships but will cost you. Also you probably want to charge on the plane anyway so might as well carry it with you. The transition from airport to seaport is very important and making sure you have enough energy on your phone to get an Uber, look up directions, and in an emergency call the cruise line is very important.",
    sub_title_8: "Cash",
    sub_article_8:
      "Since there is a potential for your suitcase not to make it onto the ship, you better make sure to have all your cash for your trip on you. ATMs are available but will have high fees. You’ll want to have cash to tip the porters collecting your bags, and for ports of call where it is more common for vendors to take cash instead of credit cards. Depending on your drink and meal package you may also want cash to tip the crew in restaurants and your cabin attendant.",
    sub_title_9: "Formal Night Clothes",
    sub_article_9:
      "One of the most disappointing parts about not having your luggage is missing out on formal night. We missed out on all the fun of dressing up, because our luggage was somewhere between Puerto Rico and St Kitts. Due to this we always pack our nice clothes in the carry on.",
    sub_title_10: "Personal Items",
    sub_article_10:
      "This category is a little broad for a reason. There’s probably something you can’t live without on the ship. Maybe it’s that book you were meaning to read, maybe its an ipad to watch movies on the ship’s Wi-Fi, or maybe it’s your child’s favorite stuffed animal they absolutely cannot live without. If you can’t picture yourself without on the ship, it goes on the carry-on.",
    sub_title_11: "",
    sub_article_11: "Need more travel advice?",
    sub_title_12: "",
    sub_article_12: "Check out our other advice articles!",
    link: "10-must-haves-for-your-carry-on",
    next_link: 6,
  },
  {
    id: 6,
    title: "4 Reasons to GET the Specialty Dining Package",
    article:
      "So many things about cruising come down to personal preference, and whether you choose to get the specialty dining package will depend on many factors such as budget, favorite foods, etc. So rather than making a blanket recommendation we have decided to list four VERY good reasons to get the dining package. You can also see our other article afterwards to see four reasons NOT to good the specialty dining package. So, What’s the Specialty Dining Package?",
    image: "https://testestestestext.s3.us-west-2.amazonaws.com/IMG_2333.jpg",
    sub_title_1: "",
    sub_article_1:
      "Most major cruise lines include meals in the price of the ticket. This usually means either table service in the main dining room or serving yourself on one of the ship’s gigantic buffet lines.  These are great options, but there’s more! Modern cruise lines have additional specialty restaurants where for an additional fee you can eat higher quality meals in a more private area of the ship. Bigger ships will have multiple options with classic steakhouses, sushi bars, teppanyaki, Brazilian steakhouses, sports bars, and a myriad of other options to enjoy. Lots of different options are available, and many cruisers may choose to dine in one or two restaurants during their trip. If you buy the specialty dining package you can dine in ANY of these restaurants as many times as you want on your trip. (Check with your cruise line to determine the exact terms of your package, but usually this means eating at least every lunch and dinner in a restaurant of your choice as long as its open). Ok now we know what this is, but should YOU get it? Below are 5 very good reasons to get the package. (Check here for 5 reasons NOT to get it).",
    sub_title_2: "The food is really good!",
    sub_article_2:
      "Many of the specialty dining restaurants are on par with their counterparts on land. While they may not be Michelin rated, we have found that the specialty restaurants are always consistent with what we would have expected to pay on land, and in some cases much cheaper than similar quality restaurants available on port days. We’ve taken multiple cruises a year since 2019 and in that time, we have only been disappointed one or two times, and when that happened it helped that we had the specialty package! (It hurts a lot less if you are already getting a great deal on dining). If you like variety and better quality than what is available in the main dining room, and the buffet then the specialty dining package is definitely for you. Bonus: Drink packages are generally accepted in specialty dining areas where you can get special cocktails or wine bottles not available anywhere else on the ship!",
    sub_title_3: "It’s actually kind of a good deal",
    sub_article_3:
      "Specialty dining fees can range from $15 dollars for the special burger joints to $50 or $60 for the more upscale offerings. Like most things on cruises, package prices vary by ship and itinerary. We have found that the dining package is generally a good deal as long as you intend to maximize its use. When we are trying to decide on whether to get the package, we will make a list of all the restaurants we want to go to and when during our trip. If the price is reasonably lower than what we would have paid for otherwise, then we get the package. Simple as that. If it’s not we may pre-buy a specific meal to save money, or in some cases forgo specialty restaurants altogether.",
    sub_title_4: "Every meal becomes a little less stressful",
    sub_article_4:
      "At Cruise Newbie, we love the endless options of the buffet just like everyone else and are constantly impressed with the dishes in the main dining room. That said, sometimes it can get exhausting waiting for your table in the main dining room or having to outmaneuver other families to get that 4-person table by the window. Also, cruise servers are incredibly hard working both at the buffet and the dining room, but it’s impossible to deliver the same quality of service in a dining room for 40 people versus a dining room for 800. The dining package instantly upgrades your experience. Reservations can be easily made at the front desk of most of the specialty restaurants or through the app. When your reservation time comes you may wait 5-10 minutes for your table to be made ready. By comparison you may wait 30 minutes or longer even if you arrive at your scheduled time for the main dining room. Additionally, you’ll get much more elbow room as tables are spread apart more. We love meeting other cruisers in the main dining room, but sometimes it’s just nice to have a little space privacy and convenience.",
    sub_title_5: "It’s great for families. Were serious!",
    sub_article_5:
      "Believe it or not we think the dining package is great for families, but for two very different reasons. First, we’ve seen the price for a child’s dining package as low as one third of the price adults pay. Meaning you can bring the little ones in on the experience at a fraction of the price. Almost all the restaurants offer kid friendly food, and some of them are even amazing experiences for the kids. The teppanyaki and the Alice in wonderland themed restaurants on some ships are extra fun and frequented by families. The second option is to only get the package for the grown-ups. This sounds mean at first, but it makes perfect sense when you consider the different things cruises have to offer. You can let the kids get their favorites at the buffet then take them to the daycare center on the ship. They can have fun while you get some peace and quiet. ",
    sub_title_6: "",
    sub_article_6:
      "So, should you get the specialty dining package? Well, that’s up to you, but if you’d like to consider some of the cons.",
    link: "4-reasons-to-get-the-specialty-dining-package",
    next_link: 1,
  },
  {
    id: 7,
    title: "Should I get the drink package? Part 2: The Intangibles",
    article:
      "So, you have done the math according to the example in part 1 (here), and you’re not quite convinced the drink package is for you. Maybe you don’t think you’ll drink as much as the example in the previous article says, or maybe you think cruise drinks are overpriced to begin with. Those are good reasons not to get the package, but the drink package is not only about “the math”. There are still some very persuasive reasons why the drink package still makes sense. Even if you actually don’t break even on the numbers!",
    image: "https://testestestestext.s3.us-west-2.amazonaws.com/IMG_2057.jpg",
    sub_title_1: "Intangible #1: No budgeting, kinda…",
    sub_article_1:
      "Ok, so we just got done with a whole article talking about numbers and how to figure out a fair amount you can plug into your budget, and now you’re saying the drink package means you don’t have to do budgeting? How does that make sense?!",
    sub_title_2: "",
    sub_article_2:
      "It does when you realize that buying the drink package is a one time budgeting event. You book your cruise, you wait till the package goes on sale, and book it before you board. That’s it. All your budgeting for drinks onboard is done, because from this point no matter how many bottles of water, mojito’s, or protein smoothies you order the price will be the same. That means one less thing to worry about on vacation! Do you really want to be running a tally of how many bottles of water you ordered to keep hydrated on the dance floor? Of course not! When you get the package you can just relax and not worry about an ugly bill at the end of your trip.",
    sub_title_3: "Intangible #2: Tipping is already covered",
    sub_article_3:
      "Speaking of bills, most drink packages already include gratuities. This means that while on the ship you don’t have to worry about how much you need to be tipping. It’s already covered! On most bars to get a drink you get the bartender’s attention, order, hand over your Sea Pass card (which serves as both your room key and credit card onbard), the bartender hands you your drink and card back, and you’re done! No having to wait for the bartender to run your card, print out the receipt, and then having to do the math to figure out what the appropriate tip is, add it to the total, then make sure the bartender gets it.",
    sub_title_4: "",
    sub_article_4:
      "We estimate the drink package makes the process 2-3 times faster depending on how busy the bartender is. Who wants to spend time waiting to get your bill, when the bartender can just scan your Sea Pass card, hand you a beer, and you’re back in the hot tub!  Note: Some restaurants may still hand you a regular bill at the end of your meal. You will find that drinks were either charged separately, and if they were not, they should be marked as $0.00 meaning you still do not have to factor them into your gratuity calculations. (Check your bill as lines, ships, and even restaurants on the same ship may vary on their billing practices).",
    sub_title_5: "Intangible #3: Upgrade to the good stuff!",
    sub_article_5:
      "Most cruise lines already use quality spirits to make drinks, so you don’t have to worry that you’ll be drinking the cheap stuff. That being said almost all bars are stocked with top shelf items. Depending on the drink you order, top shelf liquor may be included in your package! This means you can order a drink like a Jack and Coke, then ask if the drink package will allow you to upgrade to a top shelf option. We are often surprised and what can be upgraded to at no additional cost with the package! There are definitely high-level liquors we were able to try for the first time, because the upgrade was already covered by the drink package.",
    sub_title_6: "Intangible #4: Try new things!",
    sub_article_6:
      "Never had a Sazerac? Just order it! If you hate it just send it back and get something else! When you have the drink package you can do this as many times as you want. This is a great way to try new drinks you have never had before without the risk of spending $8 to $12 on something you will hate. See a cool drink at the table next door? Order it! Additionally, many cruise lines will have  a daily drink on each day of the cruise, with the package you can try them all without running the risk of paying for something you won’t like.",
    sub_title_7: "Intangible #5: Unlimited bottled water",
    sub_article_7:
      "This may seem like a small perk but it really adds up! Remember most cruises are in hot tropical climates, where you will be doing all sorts of activities, from swimming, dancing, and even just old fashioned walking. Add to that the presence of alcoholic drinks, and your body will practically be screaming for water. With the drink package you can get as many bottles of water as you want, and in some ships even get sports drinks to quench your thirst! The bottom line: You’re on vacation!",
    sub_title_8: "",
    sub_article_8:
      "Ultimately it all comes down to whether you think the drink package will enable you to enjoy your vacation more. For us personally we have found that it removes a lot of the stress of having to budget, and allows us to just kick back and relax. It’s hard to measure these types of things and that’s why we consider them “Intangibles”. ",
    link: "should-i-get-the-drink-package-part2-the-intangibles",
    next_link: 3,
  },
];
