import React from "react";
import { Typography, Container } from "@mui/material";
import "./About.css";

const Privacy = () => {
  return (
    <div>
      <Typography variant="h3" align="center" id="about-top">
        Privacy Policy
      </Typography>
      <Container maxWidth="md">
        <ul>
          <List text={"Coming soon"}></List>
        </ul>
      </Container>
    </div>
  );
};

const List = ({ text }) => {
  return (
    <>
      <li className="privacy-list">
        <Typography variant="body1" gutterBottom fontSize={20}>
          {text}
        </Typography>
      </li>
    </>
  );
};

export default Privacy;
