import React from "react";
import { useState, useEffect } from "react";
import logo from "../Images/Cruisenewbie.png";
import "./ArticleMain.css";
import TextField from "@mui/material/TextField";
import SmallCard from "../Cards/SmallCard";
import { Box, Container, Grid, Typography } from "@mui/material";
import { articles } from "../../articles/article1";

const ArticleMain = () => {
  const [value, setValue] = useState("");
  const [search, setSearch] = useState(articles);

  const handleChange = ({ target }) => {
    setValue(target.value);
  };

  useEffect(() => {
    setSearch(
      articles.filter((s) =>
        s.title.toLowerCase().includes(value.toLowerCase())
      )
    );
  }, [value]);

  return (
    <div id="article-main">
      <div className="main-logo">
        <img src={logo} id="logo" />
      </div>
      <Container fixed disableGutters maxWidth="lg">
        <Typography variant="h3" className="all-articles-head">
          {`Display Articles: ${search.length}`}
        </Typography>
        <Box sx={{ textAlign: "center" }}>
          <TextField
            label="Search"
            margin="normal"
            onChange={handleChange}
            className="all-articles-search-field"
            sx={{
              marginBottom: "2rem",
              width: "70%",
            }}
          />
        </Box>

        <Grid container spacing={3}>
          {search.map((article) => {
            return (
              <SmallCard
                image={article.image}
                title={article.title}
                key={article.id}
                link={article.link}
              />
            );
          })}
        </Grid>
      </Container>
    </div>
  );
};

export default ArticleMain;
