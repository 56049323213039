import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import LargeCard from "./LargeCard";
import { articles } from "../../articles/article1";

export default function CardContainer() {
  const replaceDot = (article) => {
    const _article = article.split(" ");
    let panelWord = [];
    for (let i = 0; i < 16; i++) {
      panelWord.push(_article[i]);
    }
    panelWord = panelWord.join(" ");
    panelWord += " ...";
    return panelWord;
  };

  return (
    <Grid container spacing={2}>
      {articles.slice(0, 6).map((item, index) => {
        return (
          <Grid item xs={12} sm={6} md={4} key={item.id}>
            {/*{index % 5 !== 0 || index === 0 ? (
              <LargeCard
                key={item.title}
                title={item.title}
                article={replaceDot(item.article)}
                image={item.image}
                link={item.link}
                hide={false}
              />
            ) : (
              <LargeCard hide={true} />
            )} */}
            <LargeCard
              key={item.title}
              title={item.title}
              article={replaceDot(item.article)}
              image={item.image}
              link={item.link}
              hide={false}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}
