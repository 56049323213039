import React, { useState } from "react";
import "./GalleryMain.css";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

function GalleryMain() {
  const [image, setImage] = useState(0);

  const imageLinks = [
    "https://testestestestext.s3.us-west-2.amazonaws.com/IMG_2407.jpg",
    "https://testestestestext.s3.us-west-2.amazonaws.com/IMG_2361.jpg",
    "https://testestestestext.s3.us-west-2.amazonaws.com/IMG_2403.jpg",
    "https://testestestestext.s3.us-west-2.amazonaws.com/image-ship.jpg",
  ];

  return (
    <div className="galleryMain">
      <div
        className="galleryInner"
        style={{ backgroundImage: `url(${imageLinks[image]})` }}
      >
        <div
          className={image != 0 ? "left" : ""}
          onClick={() => {
            image > 0 && setImage(image - 1);
          }}
        >
          {image != 0 ? (
            <ArrowBackIosNewIcon style={{ fontSize: 30 }} />
          ) : (
            <></>
          )}
        </div>
        <div className="center"></div>
        <div
          className={image != imageLinks.length - 1 ? "right" : ""}
          onClick={() => {
            image < imageLinks.length - 1 && setImage(image + 1);
          }}
        >
          {image != imageLinks.length - 1 ? (
            <ArrowForwardIosIcon style={{ fontSize: 30 }} />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

export default GalleryMain;
