import React from "react";
import logo from "../Images/Cruisenewbie.png";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { Margin } from "@mui/icons-material";

const Error = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <div>
        <img src={logo} />
      </div>
      <div>
        <Typography variant="body1" fontSize={20} margin={5} padding={1}>
          Page is not found. <br />
          Please try to go back to the previous page or click the button to go
          back to the main page
        </Typography>
      </div>
      <div>
        <Link to="/">
          <Button variant="contained" sx={{ margin: 5 }}>
            Main Page
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default Error;
