import React from "react";
import { Typography, Container, Box } from "@mui/material";
import "./About.css";
import logo from "../Images/Cruisenewbie.png";

const About = () => {
  return (
    <div>
      <Typography variant="h3" align="center" marginTop={"3%"} marginBottom={0}>
        About CruiseNewbie
      </Typography>
      <Box textAlign={"center"}>
        <img src={logo} />
      </Box>
      <Container maxWidth="md">
        <Typography variant="subtitle1" gutterBottom fontSize={18}>
          We create this page for cruise newbies. We have started crusing since
          2019. You know what, we lost our laggage at first cruise.
        </Typography>
      </Container>
    </div>
  );
};

export default About;
