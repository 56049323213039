import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AppBar, Box, Button, Menu, MenuItem, Toolbar } from "@mui/material";
import { articles } from "../articles/article1";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";

const Nav = () => {
  const [latest, setLatest] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    console.log(event.currentTarget);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setLatest(articles[articles.length - 1].link);
  }, []);

  return (
    <AppBar
      color="inherit"
      position="sticky"
      sx={{ backgroundColor: "#0081C9" }}
    >
      <Toolbar>
        <Box
          sx={{
            flexGrow: 2,
            display: {
              xs: "flex",
              md: "none",
              justifyContent: "center",
              my: 2,
            },
          }}
        >
          <Button
            id="menu-button"
            aria-controls={open ? "menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <MenuRoundedIcon
              fontSize="large"
              sx={{
                color: "whitesmoke",
                mx: 3,
                justifyContent: "center",
              }}
            />
          </Button>
          <Menu
            id="menu"
            anchorEl={anchorEl}
            open={open}
            MenuListProps={{
              "aria-labelledby": "menu-button",
            }}
            onClose={handleClose}
            sx={{
              display: {
                xs: "flex",
                md: "none",
              },
            }}
          >
            <Link to={"/"}>
              <MenuItem onClick={handleClose}>Home</MenuItem>
            </Link>
            <Link to={"/articles"}>
              <MenuItem onClick={handleClose}>Articles</MenuItem>
            </Link>
            <Link to={`/article/${latest}`}>
              <MenuItem onClick={handleClose}>Latest Article</MenuItem>
            </Link>
          </Menu>
        </Box>

        <Box
          sx={{
            flexGrow: 2,
            display: { xs: "none", md: "flex" },
            justifyContent: "center",
            my: 2,
          }}
        >
          <Link to={"/"}>
            <Button
              sx={{
                color: "whitesmoke",
                fontWeight: "bold",
                fontSize: 18,
                mx: 3,
              }}
            >
              Home
            </Button>
          </Link>
          <Link to={"/articles"}>
            <Button
              sx={{
                color: "whitesmoke",
                fontWeight: "bold",
                fontSize: 18,
                mx: 3,
              }}
            >
              Articles
            </Button>
          </Link>
          <Link to={`/article/${latest}`}>
            <Button
              sx={{
                color: "whitesmoke",
                fontWeight: "bold",
                fontSize: 18,
                mx: 3,
              }}
            >
              Latest Article
            </Button>
          </Link>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Nav;
