import React from "react";
import { Card, CardMedia, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const SmallCard = (props) => {
  return (
    <Grid item xs={12} md={4}>
      <Card variant="outlined">
        <Link to={`/article/${props.link}`}>
          <Typography variant="h6" fontSize={16} margin={1} height={55}>
            {props.title}
          </Typography>
          <CardMedia
            component="img"
            image={props.image}
            alt={props.title}
            height={150}
          />
        </Link>
      </Card>
    </Grid>
  );
};

export default SmallCard;
