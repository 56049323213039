import { Container, CardMedia, Typography, Box, Button } from "@mui/material";
import { articles } from "../../articles/article1";
import { useParams, Link } from "react-router-dom";
import "./Article.css";

export default function Article() {
  let { link } = useParams();

  const article = articles.find((item) => item.link === link);

  const subTitles = [];
  const subArticles = [];
  for (let key in article) {
    if (key.includes("sub_title_")) {
      subTitles.push(article[key]);
    }
    if (key.includes("sub_article_")) {
      subArticles.push(article[key]);
    }
  }

  let loopIndex = 0;
  if (subTitles.length > subArticles.length) {
    loopIndex = subTitles.length;
  } else {
    loopIndex = subArticles.length;
  }

  let tempArticle = Object.assign({}, article);
  delete tempArticle.id;
  delete tempArticle.title;
  delete tempArticle.image;
  delete tempArticle.link;
  delete tempArticle.article;

  // Need to update
  // if next_link is null
  const nextArticle = articles.find((item) => item.id === article.next_link);

  return (
    <Container fixed disableGutters maxWidth="md" className="article-top">
      <CardMedia
        component="img"
        height="400"
        width="100vw"
        image={article.image}
        alt={article.article_en}
        className="article-image"
        key={article.id}
      />
      <Typography variant="h4" marginTop={3} marginBottom={3}>
        {article.title}
      </Typography>
      <Typography variant="body1">{article.article}</Typography>
      {Object.keys(tempArticle).map((key, index) => {
        index += 1;
        return (
          <SubSentence
            subTitle={tempArticle[`sub_title_${index}`]}
            subArticle={tempArticle[`sub_article_${index}`]}
          />
        );
      })}
      <Box display="flex" justifyContent="space-between">
        {" "}
        <Link to={"/"}>
          <Button color="primary">Back to Main</Button>
        </Link>
        <Link to={`/article/${nextArticle.link}`}>
          <Button>Next Article</Button>
        </Link>
      </Box>
    </Container>
  );
}

const SubSentence = (props) => {
  const { subTitle, subArticle } = props;
  return (
    <>
      <Typography variant="h5" marginTop={3} marginBottom={3}>
        {subTitle}
      </Typography>
      <Typography variant="body1">{subArticle}</Typography>
    </>
  );
};
