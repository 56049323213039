import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import About from "./components/About/About";
import Nav from "./components/Nav";
import Main from "./components/Main";
import Article from "./components/Articles/Article";
import GalleryMain from "./components/Gallery/GalleryMain";
import Footer from "./components/Footer";
import Grid from "@mui/material/Grid";
import ArticleInput from "./components/NewArticle/ArticleInput";
import ArticleMain from "./components/Articles/ArticleMain";
import Error from "./components/Error/Error";
import ScrollToTop from "./components/Helpers/ScrollToTop";
import Privacy from "./components/About/Privacy";
import Contact from "./components/About/Contact";

function App() {
  return (
    <React.Fragment>
      <Router>
        <ScrollToTop />
        <div id="container">
          <Nav />
          <div id="app">
            <Grid>
              <Routes>
                <Route path="/" exact element={<Main />} />
                <Route path="/about" element={<About />} />
                <Route path="/article/:link" element={<Article />} />
                <Route path="/articles" element={<ArticleMain />} />
                <Route path="/newarticle" element={<ArticleInput />} />
                <Route path="/gallery" element={<GalleryMain />} />
                <Route path="/privacy-policy" element={<Privacy />} />
                <Route path="*" exact element={<Error />} />
              </Routes>
            </Grid>
          </div>
          <Footer />
        </div>
      </Router>
    </React.Fragment>
  );
}

export default App;
