import React, { useState } from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div id="footer">
      <p id="copyright">
        &copy; {new Date().getFullYear()} CruiseNewbie All rights reserved
      </p>
      <div id="footer-links">
        <Link to="privacy-policy" id="privacy-policy-link">
          Privacy Policy
        </Link>
        <Link to="about" id="privacy-policy-link">
          About Us
        </Link>
      </div>
    </div>
  );
};

export default Footer;
