import React from "react";

export default function ArticleInput() {
  return (
    <div>
      <h1>InputPage</h1>
      <form>
        <label>Title</label>
        <label>Article</label>
      </form>
    </div>
  );
}
